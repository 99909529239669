<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" placeholder="请选择状态" size="small">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.store" clearable placeholder="请选择门店" size="small">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker size="small"
                        v-model="dateRange"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10" v-show="showBtn">
      <el-button type="primary" size="small" @click="sureDeliveryAll()">合并发货</el-button>
      <el-button type="warning" size="small" @click="createDeliveryAll()">生成发货单</el-button>
      <el-tooltip class="item" effect="dark" content="触发后订单按门店依次展示" placement="top">
        <el-button type="success" size="small" @click="sortOrder(1)" v-if="search.sort==0">一键筛选</el-button>
        <el-button type="info" size="small" @click="sortOrder(0)" v-else>取消筛选</el-button>
      </el-tooltip>
      <el-button type="danger" size="small" @click="dialogLogVisible=true">发货记录</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column v-if="showBtn"
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="order_sn"
          label="订单号"
          min-width="130">
      </el-table-column>
      <el-table-column
          prop="goods"
          label="商品"
          width="450">
        <template slot-scope="scope">
          <table width="100%" class="gdtable">
            <tr v-for="(item,index) in scope.row.goods" :key="index" style="background-color: unset;border-bottom: 1px solid #f5f5f5">
              <td style="padding-bottom: 10px;width: 50px;"><img :src="item.goods_img" style="width: 40px;height: 40px;"></td>
              <td style="text-align: left;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{item.goods_name}} <span v-if="item.specs_name">/ {{item.specs_name}}</span></td>
              <td style="width: 200px; text-align: right">￥{{item.price}} x{{item.quantity}}<br><span v-if="item.hourse_name">发货仓库：{{item.hourse_name}}</span></td>
            </tr>
          </table>
        </template>
      </el-table-column>
      <el-table-column
          prop="store_str"
          label="下单门店"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="user_id_str"
          label="下单人"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="remark"
          label="用户留言"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="address"
          label="收货地址"
          align="left"
          width="190">
        <template slot-scope="scope">
          <span>{{scope.row.address.name}} {{scope.row.address.mobile}}<br>
            {{scope.row.address.province_name}}{{scope.row.address.city_name}}{{scope.row.address.district_name}}{{scope.row.address.address}}
            <el-button type="text" id="copyId" @click="copyTxt(scope.row.address.name + '，' + scope.row.address.mobile + '，' + scope.row.address.province_name+scope.row.address.city_name+scope.row.address.district_name+scope.row.address.address)" size="small">复制</el-button>
          </span>
        </template>
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="订单状态"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="package"
          label="发货信息"
          align="left"
          width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.package">{{scope.row.package.logis_name}}<br>{{scope.row.package.logis_no}}<br>备注：{{scope.row.shipments_user_name}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="下单时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.status==2">
            <el-button type="text" size="small" @click="dialogDeliveryVisible=true; delivery.type=1; delivery.ids=[scope.row.id]; itemInfo=JSON.parse(JSON.stringify(scope.row))">发货</el-button>
            <el-button type="text" size="small" @click="createFahuo([scope.row.id])">生成发货单</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="发货"
        :visible.sync="dialogDeliveryVisible"
        width="30%">
      <div style="margin-bottom: 10px;font-size: 14px;">请选择快递：</div>
      <el-select v-model="delivery.logis_code" placeholder="请选择快递" size="small">
        <el-option
            v-for="item in setInfo.delivery"
            :key="item.id"
            :label="item.title"
            :value="item.id">
        </el-option>
      </el-select>
      <div style="margin-bottom: 10px; margin-top: 20px;font-size: 14px;">请填写快递单号：</div>
      <el-input v-model="delivery.logis_no" placeholder="请填写快递单号" size="small"></el-input>
      <div style="margin-bottom: 10px; margin-top: 20px;font-size: 14px;">备注：</div>
      <el-input v-model="delivery.name" placeholder="请填写备注" size="small"></el-input>
      <div style="margin-top: 20px;font-size: 14px;">上传发货凭证：</div>
      <div class="mg-20">
        <SelectImgs :selectNum="3" :selectData="delivery.pics" :selectFunc="imgChoose" :deleteFunc="deleteFunc"></SelectImgs>
        <span style="color: #ccc;">最多3张图</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeliveryVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="sureDelivery()" size="small">确认发货</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="发货单"
        :visible.sync="dialogFahuoVisible"
        width="600">
      <div ref="print">
        <div style="font-size: 16px; text-align: center; margin-bottom: 30px">发货单</div>
        <table width="100%" border="1" class="print-box">
          <tr>
            <td colspan="5" v-if="fahuoInfo.address">
              <div class="bx">
                <span><b>收货人：</b>{{fahuoInfo.address.name}}</span>
                <span><b>联系电话：</b>{{fahuoInfo.address.mobile}}</span>
                <span><b>下单门店：</b>{{fahuoInfo.shop_name}}</span>
                <span><b>下单时间：</b>{{fahuoInfo.create_time}}</span>
                <span v-if="fahuoInfo.supplier_name"><b>供应商：</b>{{fahuoInfo.supplier_name}}</span>
              </div>
              <div class="bx">
                <b>收货地址：</b>{{fahuoInfo.address.province_name}}{{fahuoInfo.address.city_name}}{{fahuoInfo.address.district_name}}{{fahuoInfo.address.address}}
              </div>
              <div class="bx">
                <b>用户留言：</b>{{fahuoInfo.remark}}
              </div>
            </td>
          </tr>
          <tr class="menu">
            <td>序号</td>
            <td>商品名称</td>
            <td>规格</td>
            <td>发货仓库</td>
            <td>数量</td>
          </tr>
          <tr class="goods" v-for="(item,index) in fahuoInfo.goods" :key="index">
            <td width="80">{{index+1}}</td>
            <td>{{item.goods_name}}</td>
            <td width="200">{{item.specs_name||'-'}}</td>
            <td width="200">{{item.hourse_name||'-'}}</td>
            <td width="100">x {{item.quantity}}</td>
          </tr>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFahuoVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="printOrder()" size="small">打印</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="发货记录"
        :visible.sync="dialogLogVisible"
        width="70%">
      <shipments-log></shipments-log>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import ClipboardJS from "clipboard";
import ShipmentsLog from "@/components/ShipmentsLog";
export default {
  components: {
    SelectImgs,
    ShipmentsLog
  },
  data() {
    return {
      info: false,
      dialogDeliveryVisible: false,
      dialogFahuoVisible: false,
      dialogLogVisible: false,
      showBtn: true,
      dateRange: ['',''],
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: 3,
        store: '',
        sort: 0
      },
      tableData: [],
      statusList: [
        {id:3,title:'待发货'},
        {id:21,title:'已发货'},
      ],
      storeList: [],
      itemInfo: false,
      setInfo: false,
      delivery: {
        type: 1,
        ids: [],
        logis_code: '',
        logis_no: '',
        name: '',
        pics: []
      },
      fahuoInfo: false
    };
  },
  created() {
    this.getSetInfo()
    this.getStoreList()
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    imgChoose(item) {
      var _this = this
      item.forEach(function (pic) {
        if(_this.delivery.pics.length < 3) {
          _this.delivery.pics.push(pic.pic)
        }
      })
    },
    deleteFunc(idx) {
      var imgs = JSON.parse(JSON.stringify(this.delivery.pics))
      imgs.splice(idx,1)
      this.delivery.pics = imgs
    },
    getSetInfo() {
      this.$api.merchant.sysSet(res => {
        if (res.errcode == 0) {
          this.setInfo = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 100}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        sort: that.search.sort,
        store: that.search.store,
        start_time: this.dateRange[0],
        end_time: this.dateRange[1],
      }
      this.$api.order.orderIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.showBtn = that.search.status==3
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    copyTxt(txt) {
      var _this = this
      var clipboard = new ClipboardJS('#copyId', {
        text: function () {
          return txt;
        },
      });
      clipboard.on('success', function (e) {
        _this.success('复制成功')
      });
      clipboard.on('error', function (e) {
        _this.fail('复制失败')
      });
    },
    sureDeliveryAll() {
      var _this = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        _this.tips('请选择发货项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确定多订单一起发货吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.delivery.type = 2
        _this.delivery.ids = ids
        _this.dialogDeliveryVisible = true
      }).catch(() => {

      });
    },
    sureDelivery() {
      var params = {
        ids: this.delivery.ids,
        logis_code: this.delivery.logis_code,
        logis_no: this.delivery.logis_no,
        name: this.delivery.name,
        pics: this.delivery.pics
      }
      if(params.logis_code == '') {
        this.fail('请选择快递')
        return false
      }
      if(params.logis_no == '') {
        this.fail('请填写快递单号')
        return false
      }
      if(params.name == '') {
        this.fail('请填写发货人')
        return false
      }
      if(params.pics.length < 1) {
        this.fail('请上传快递截图')
        return false
      }
      this.$api.order.orderDelivery(params, res => {
        if (res.errcode == 0) {
          this.delivery.logis_code = ''
          this.delivery.logis_no = ''
          this.dialogDeliveryVisible = false
          this.delivery.name = ''
          this.delivery.pics = []
          this.success('发货成功')
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    createDeliveryAll() {
      var _this = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        _this.tips('请选择要生成的订单')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.createFahuo(ids)
    },
    createFahuo(ids) {
      this.$api.order.orderInvoice({ids: ids}, res => {
        if (res.errcode == 0) {
          this.fahuoInfo = res.data
          this.dialogFahuoVisible = true
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    sortOrder(sort) {
      this.search.sort = sort
      this.handleSearch()
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    },
    printOrder() {
      this.$print(this.$refs.print)
    }
  }
};
</script>
<style scoped>
.gdtable tr:last-child {
  border-bottom: unset !important;
}
</style>
