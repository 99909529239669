<template>
  <div class="">
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="order_sn"
          label="订单号"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="goods"
          label="商品"
          width="450">
        <template slot-scope="scope">
          <table width="100%" class="gdtable">
            <tr v-for="(item,index) in scope.row.goods" :key="index" style="background-color: unset;border-bottom: 1px solid #f5f5f5">
              <td style="padding-bottom: 10px;width: 50px;"><img :src="item.goods_img" style="width: 40px;height: 40px;"></td>
              <td style="text-align: left;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{item.goods_name}} <span v-if="item.specs_name">/ {{item.specs_name}}</span></td>
              <td style="width: 100px; text-align: right">￥{{item.price}} x{{item.quantity}}</td>
            </tr>
          </table>
        </template>
      </el-table-column>
      <el-table-column
          prop="store_str"
          label="下单门店"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="address"
          label="收货地址"
          align="left"
          width="190">
        <template slot-scope="scope">
          <span>{{scope.row.address.name}} {{scope.row.address.mobile}}<br>
            {{scope.row.address.province_name}}{{scope.row.address.city_name}}{{scope.row.address.district_name}}{{scope.row.address.address}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          prop="user_id_str"
          label="发货人"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="package"
          label="发货信息"
          align="left"
          width="160">
        <template slot-scope="scope">
          <span>{{scope.row.logis_name}}<br>{{scope.row.logis_no}}<br>备注：{{scope.row.shipments_user_name}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="package"
          label="发货凭证"
          align="left"
          width="220">
        <template slot-scope="scope">
          <span v-for="(item,index) in scope.row.shipments_pic" :key="index">
            <img :src="item" width="60" height="60" style="margin-right: 5px">
          </span>
        </template>
      </el-table-column>
      <el-table-column
          prop="shipments_time"
          label="发货时间"
          align="center"
          width="140">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        search: {
          current_page: 1,
          total: 0,
          limit: 10,
          keywords: '',
          status: 3,
          store: '',
          sort: 0
        },
        tableData: [],
      }
    },
    components: {
    },
    props: {
      selectNum: Number,
      selectFunc: Function,
      deleteFunc: Function,
      selectData: Array,
      idx: Number
    },
    created() {
      this.getList();
    },
    mounted() {
    },
    methods: {
      getList() {
        var that = this
        that.showLoading()
        var param = {
          page: that.search.current_page,
          page_size: that.search.limit,
          store: that.search.store
        }
        this.$api.order.orderShipmentsLog(param, function (res) {
          that.hideLoading()
          if (res.errcode == 0) {
            that.showBtn = that.search.status==3
            that.tableData = res.data.data
            that.search.current_page = res.data.current_page
            that.search.total = res.data.total
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      handleSearch() {
        this.search.current_page = 1
        this.getList()
      },
      handleSelectionChange(rows) {
      },
      handleCurrentChange(val) {
        this.search.current_page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.search.current_page = 1
        this.search.limit = val
        this.getList()
      }
    }
  }
</script>

<style scoped>
</style>
